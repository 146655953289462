import { IProductToCardParams } from '../api/cart/productToCardAppend';
import { CartPrice, CartProduct } from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { ICartPrice, ICartProductWidthId } from '../slices/cartSlice';
import { Price } from '@teleport/schemas-protobuf';
import { IPrice } from '../../types/price';

export const CartTranslator = {
  toProductToCardAppendRequest(params: IProductToCardParams) {
    return new CartProduct({ productUuid: params.productUuid, quantity: 1, chosenModifiers: params.chosenModifiers });
  },

  toCartProductWidthId(product: CartProduct): ICartProductWidthId {
    return {
      id: `${product.productUuid}-${product.chosenModifiers.map(modifier => modifier.modifierVariantUuid.slice(0, 4)).join('-')}`,
      productUuid: product.productUuid,
      quantity: product.quantity,
      chosenModifiers: product.chosenModifiers,
      productTitle: product.productTitle,
      productImageUrl: product.productImageUrl,
      productIsFavorite: product.productIsFavorite,
    }
  },

  toCartProduct(product: ICartProductWidthId): CartProduct{
    return new CartProduct({
      productUuid: product.productUuid,
      quantity: product.quantity,
      chosenModifiers: product.chosenModifiers,
      productTitle: product.productTitle,
      productImageUrl: product.productImageUrl,
      price: {
        oldAmount:  BigInt(product.price.oldAmount),
        amount: BigInt(product.price.amount),
        discountEnabled: product.price.discountEnabled,
        discountInPercent: product.price.discountInPercent
      },
      productIsFavorite: product.productIsFavorite,
    })
  },

  toPrice(price: Price): IPrice {
    return {
      amount: Number(price.amount),
      oldAmount: Number(price.oldAmount),
      discountInPercent: price.discountInPercent,
      discountEnabled: price.discountEnabled
    }
  },

  toCartPrice(cartPrice: CartPrice): ICartPrice {
    return {
      productTotal: CartTranslator.toPrice(cartPrice.productTotal),
      promocodeDiscount: Number(cartPrice.promocodeDiscount),
      cartTotal: Number(cartPrice.cartTotal),
    }
  },

  toIncrementArray(products: ICartProductWidthId[], incrementProductId: string ): CartProduct[] {
    return products.map(el => {
      if(el.id === incrementProductId) {
        return CartTranslator.toCartProduct({
          ...el,
          quantity: el.quantity + 1,
        })
      }

      return CartTranslator.toCartProduct(el);
    });
  },

  toDecrementArray(products: ICartProductWidthId[], incrementProductId: string ): CartProduct[] {
    return products.map(el => {
      if(el.id === incrementProductId) {
        return new CartProduct({
          productUuid: el.productUuid,
          quantity: el.quantity - 1,
          chosenModifiers: el.chosenModifiers,
        })
      }

      return CartTranslator.toCartProduct(el);
    });
  }
};
