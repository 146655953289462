import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Sorting } from '@teleport/schemas-protobuf';
import { getMetadata } from '../api/getMetadata';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { RootState } from '../store';
import { isBoolFilter, isMultiselectFilter, isRangeFilter } from 'src/utils/filters/filterCase';
import type { Category } from '@teleport/schemas-protobuf/port/v1/port_category_pb';
import { FilterTranslator } from '../translators/filterTranslator';

// selectedFilters - используется лишь для вывода на страницу
// выбранных фильтров.

// filters - текущее состояние фильтров. Когда мы меняем value конкретного фильтра,
// то изменяется именно этот массив.

interface IFilterBoolFilter {
  checked: boolean;
}

interface IFilterMultiselectFilterOption {
  title: string;
  checked: boolean;
}

interface IFilterMultiselectFilter {
  availableOptions: IFilterMultiselectFilterOption[]
}

interface IFilterRangeFilter {
  min: bigint;
  max: bigint;
  gte: bigint;
  lte: bigint;
}

interface IFilterSearchFilter {
  query: string;
}

interface IFilterCategoryFilter {
  category?: Category;
}

interface IFilterSelectionFilter {
  selectionUuid: string;
}

type FilterType = {
  value: IFilterBoolFilter;
  case: 'boolFilter';
} | {
  value: IFilterMultiselectFilter;
  case: 'multiselectFilter';
} | {
  value: IFilterRangeFilter;
  case: 'rangeFilter';
} | {
  value: IFilterSearchFilter;
  case: 'searchFilter';
} | {
  value: IFilterCategoryFilter;
  case: 'categoryFilter';
} | {
  value: IFilterSelectionFilter;
  case: 'selectionFilter';
} | { case: undefined; value?: undefined }

export interface IFilter {
  filterDefaultState: FilterType;
  tag: string;
  title: string;
  isSystem: boolean;
}

interface FilterState {
  selectedSorting: { sortingNumber: Sorting; text: string };
  selectedFilters: Array<IFilterCustom | IFilterRange>;
  filters: IFilter[];
  networkStatus: NetworkStatus;
}

export enum FiltersTypes {
  STANDART = 'standart',
  RANGE = 'range',
}

export interface IFilterRange {
  type: FiltersTypes.RANGE;
  id: string; // tag:size|name:XS
  tag: string;
  minValue: number;
  maxValue: number;
}

export interface IFilterCustom {
  type: FiltersTypes.STANDART;
  id: string; // tag:size|name:XS
  tag: string;
  value: string;
}

const initialState: FilterState = {
  selectedSorting: { sortingNumber: Sorting.UNSPECIFIED, text: '' },
  selectedFilters: [],
  filters: [],
  networkStatus: NetworkStatus.None,
};

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setSorting: (state, action: PayloadAction<{ sortingNumber: Sorting; text: string }>) => {
      state.selectedSorting = action.payload;
    },
    setFilter: (state, { payload }: PayloadAction<IFilterCustom>) => {
      const filter = state.selectedFilters.find(el => el.id === payload.id);

      if (filter && filter.type === FiltersTypes.STANDART) {
        filter.value = payload.value;
      } else {
        state.selectedFilters.push(payload);
      }
    },
    setRangeFilter: (state, { payload }: PayloadAction<IFilterRange>) => {
      const filter = state.selectedFilters.find(el => el.tag === payload.tag);
      if (filter && filter.type === FiltersTypes.RANGE) {
        filter.maxValue = payload.maxValue;
        filter.minValue = payload.minValue;
      } else {
        state.selectedFilters.push(payload);
      }
    },
    removeFilter: (state, { payload }: PayloadAction<IFilterCustom | IFilterRange>) => {
      const { tag, id, type } = payload;

      // change filters state
      const filter = state.filters.find(filter => filter.tag === tag);
      const filterIndex = state.filters.indexOf(filter);
      const { filterDefaultState } = filter;
      const value = filter.filterDefaultState.value;

      if (isBoolFilter(value, filterDefaultState.case)) {
        value.checked = false;
      } else if (isMultiselectFilter(value, filterDefaultState.case)) {
        if (type === FiltersTypes.STANDART) {
          const option = value.availableOptions.find(el => el.title === payload.value);
          if (option) option.checked = false;
        }
      } else if (isRangeFilter(value, filterDefaultState.case)) {
        value.gte = value.min;
        value.lte = value.max;
      }

      state.selectedFilters = state.selectedFilters.filter(el => el.id !== id);

      const newFilters = [...state.filters];
      newFilters.splice(filterIndex, 1, filter);
      state.filters = newFilters;
    },

    resetSorting: state => {
      state.selectedSorting = initialState.selectedSorting;
    },

    resetFilters: state => {
      state.selectedFilters = [];
    },

    changeBoolFilter: (state, { payload }: PayloadAction<{ tag: string; checked: boolean }>) => {
      const filter = state.filters.find(filter => filter.tag === payload.tag);
      const filterIndex = state.filters.indexOf(filter);
      const { filterDefaultState } = filter;
      const value = filter.filterDefaultState.value;

      if (isBoolFilter(value, filterDefaultState.case)) {
        value.checked = payload.checked;
      }

      const newFilters = [...state.filters];
      newFilters.splice(filterIndex, 1, filter);
      state.filters = newFilters;
    },

    changeMultiselectFilter: (
      state,
      { payload }: PayloadAction<{ tag: string; optionTitle: string; checked: boolean }>,
    ) => {
      const { tag, optionTitle, checked } = payload;
      const filter = state.filters.find(filter => filter.tag === tag);

      if (!filter) return;

      const { filterDefaultState } = filter;
      const value = filter.filterDefaultState.value;

      if (isMultiselectFilter(value, filterDefaultState.case)) {
        const option = value.availableOptions.find(el => el.title === optionTitle);
        if (option) option.checked = checked;
      }

      const newFilters = [...state.filters];
      const filterIndex = state.filters.indexOf(filter);
      newFilters.splice(filterIndex, 1, filter);
      state.filters = newFilters;
    },

    changeRangeFilterMin: (
      state,
      { payload }: PayloadAction<{ tag: string; minValue: number }>,
    ) => {
      const { tag, minValue } = payload;
      const filter = state.filters.find(filter => filter.tag === tag);

      if (!filter) return;

      const { filterDefaultState } = filter;
      const value = filter.filterDefaultState.value;

      if (isRangeFilter(value, filterDefaultState.case)) {
        value.gte = BigInt(minValue);
      }

      const newFilters = [...state.filters];
      const filterIndex = state.filters.indexOf(filter);
      newFilters.splice(filterIndex, 1, filter);
      state.filters = newFilters;
    },

    changeRangeFilterMax: (
      state,
      { payload }: PayloadAction<{ tag: string; maxValue: number }>,
    ) => {
      const { tag, maxValue } = payload;
      const filter = state.filters.find(filter => filter.tag === tag);

      if (!filter) return;

      const { filterDefaultState } = filter;
      const value = filter.filterDefaultState.value;

      if (isRangeFilter(value, filterDefaultState.case)) {
        value.lte = BigInt(maxValue);
      }

      const newFilters = [...state.filters];
      const filterIndex = state.filters.indexOf(filter);
      newFilters.splice(filterIndex, 1, filter);
      state.filters = newFilters;
    },
  },

  extraReducers: builder => {
    builder.addCase(getMetadata.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getMetadata.fulfilled, (state, action) => {
      state.filters = action.payload.filters.map(el => FilterTranslator.toIFilter(el));
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getMetadata.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const {
  setSorting,
  setFilter,
  setRangeFilter,
  removeFilter,
  resetSorting,
  resetFilters,
  changeBoolFilter,
  changeMultiselectFilter,
  changeRangeFilterMax,
  changeRangeFilterMin,
} = filterSlice.actions;

// selectors
export const filtersState = (state: RootState) => state.filters;
