import { FC, memo, useCallback, } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SelectedFiltersRules } from './SelectedFilters.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  FiltersTypes,
  IFilterCustom,
  IFilterRange,
  removeFilter,
  resetSorting,
} from '../../redux/slices/filterSlice';
import { getProductsList } from '../../redux/api/getProductsList';
import Chip from '../UI/Chip/Chip';
import { Sorting } from '@teleport/schemas-protobuf';
import {getSearchValue, resetSearch} from 'src/redux/slices/searchSlice';
import { FilterTranslator } from 'src/redux/translators/filterTranslator';
import { categoriesState } from 'src/redux/slices/categoriesSlice';
import { useParams } from 'react-router-dom';

export const SelectedFilters: FC = memo(function SelectedFilters(props) {
  const {} = props;
  const { css } = useStyle(SelectedFiltersRules);
  const dispatch = useAppDispatch();
  const { filters, selectedFilters, selectedSorting } = useAppSelector(state => state.filters);
  const searchValue = useAppSelector(getSearchValue)
  const { selectedCategory, selectedSubCategory } = useAppSelector(categoriesState);
  const { selectionId } = useParams();

  const requestNewProductsList = useCallback((resetSearch?: boolean, resetSorting?: boolean) => {
    const filtersForRequest = [];

    if(selectedFilters.length > 0) {
      filters.forEach(filter => filtersForRequest.push(filter))
    }

    if (!resetSearch && searchValue.length >= 3) {
      filtersForRequest.push(FilterTranslator.toSearchFilter(searchValue))
    }

    if (selectedCategory) {
      filtersForRequest.push(FilterTranslator.toCategoryFilter(selectedCategory))
    }

    if (selectedSubCategory) {
      filtersForRequest.push(FilterTranslator.toSubCategoryFilter(selectedSubCategory))
    }

    if (selectionId) {
      filtersForRequest.push(FilterTranslator.toSelectionFilter(selectionId))
    }

    dispatch(getProductsList({ 
      filters: filtersForRequest, 
      sorting: resetSorting ? Sorting.UNSPECIFIED : selectedSorting.sortingNumber
    }));

  }, [
    dispatch, 
    filters, 
    searchValue, 
    selectedSorting,
    selectedCategory,
    selectedSubCategory, 
    selectionId,
    selectedFilters
  ])

  const handleRemoveFilter = (removedEl: IFilterCustom | IFilterRange) => {
    dispatch(removeFilter(removedEl));
    requestNewProductsList()
  };

  const handleresetSorting = () => {
    dispatch(resetSorting());
    requestNewProductsList(false, true)
  };

  const handleRemoveSearchValue = () => {
    dispatch(resetSearch());
    requestNewProductsList(true)
  }

  if (selectedFilters.length === 0 && !Boolean(selectedSorting.sortingNumber) && !(searchValue.length > 0)) {
    return null;
  }

  if (Boolean(selectedSorting.sortingNumber) || selectedFilters.length > 0 || searchValue.length > 0) {
    return (
      <div className={css.activeFilters}>
        {searchValue.length > 0 && <Chip type="block" text={searchValue} removeFn={handleRemoveSearchValue} />}
        {selectedSorting.sortingNumber !== Sorting.UNSPECIFIED && (
          <Chip type="block" text={selectedSorting.text} removeFn={handleresetSorting} />
        )}
        {selectedFilters.map(el => {
          if (el.type === FiltersTypes.RANGE) {
            return (
              <Chip
                key={el.id}
                type="block"
                text={`${el.minValue} - ${el.maxValue} Р`}
                removeFn={() => handleRemoveFilter(el)}
              />
            );
          }
          return (
            <Chip
              key={el.id}
              type="block"
              text={el.value}
              removeFn={() => handleRemoveFilter(el)}
            />
          );
        })}
      </div>
    );
  } else {
    return null;
  }
});
