import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductTranslator } from '../translators/productTranslator';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { RootState } from '../store';
import { createAppAsyncThunk } from '../../hooks/redux';
import { productToFavoriteBind } from './favouritesSlice';
import { IPrice } from 'src/types/price';
import { ICategory } from './categoriesSlice';
import { ISelectionBlock } from './mainSlice';
import { IModifierVariant } from './modifiersSlice';

export enum DeliveryActionType {
  Link = 'Link',
  RedeemCode = 'RedeemCode',
  Noop = 'Noop',
}

export interface IDeliveryAction {
  type: DeliveryActionType;
  value?: string;
}

export interface IProductModifierImpact {
  modifierVariantUuid: string;
  priceImpact: number;
}
export interface IClientCatalogProductFull {
  uuid: string;
  title: string;
  description: string;
  imageUrls: string[];
  isFavorite: boolean;
  price?: IPrice;
  modifiers: IProductModifierImpact[];
  recommendations: ISelectionBlock[];
  categories: ICategory[];
  deliveryAction: IDeliveryAction;
}
interface IProductDetail {
  product: IClientCatalogProductFull | null;
  networkStatus: NetworkStatus;
  chosenModifiers: IModifierVariant[];
  errorModifiersUuids: string[]
  needRedirectToProductPage: boolean;
}

const initialState: IProductDetail = {
  product: null,
  networkStatus: NetworkStatus.None,
  chosenModifiers: [],
  errorModifiersUuids: [],
  needRedirectToProductPage: true,
};

export const getProductDetail = createAppAsyncThunk(
  'product/detail',
  async (productUuid: string, thunkAPI) => {
    const result = await thunkAPI.extra.portApi.clientCatalogProductDetail({ productUuid });
    return ProductTranslator.fromStatusResponse(result);
  },
);

export const productDetailSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    changeChosenModifiers: (state, action: PayloadAction<IModifierVariant>) => {
      const variant = action.payload;
      const modifierIndex = state.chosenModifiers.findIndex(item => item.modifierUuid === variant.modifierUuid);
      const index = state.chosenModifiers.findIndex(item => item.modifierVariantUuid === variant.modifierVariantUuid);
      if (index !== -1) { // Если такой Вариант уже выбран, то убираем его 
        state.chosenModifiers = state.chosenModifiers.filter(el => el.modifierVariantUuid !== variant.modifierVariantUuid);
      } else {
        if (modifierIndex === -1 || !variant.modifierUuid) { // Если вариант добавляется впервые либо это тогл, то добавляем его
          state.chosenModifiers.push(variant);
        } else {
          state.chosenModifiers = state.chosenModifiers.map(el =>
            el.modifierUuid === variant.modifierUuid ? variant : el // Если вариант не тогл, то заменяем его на пейлоад
          );
        }
      }
    },
    clearChosenModifiersAndProduct: state => {
      state.chosenModifiers = [];
      state.product = null
    },
    setErrorModifiersUuids: (state, action: PayloadAction<string[]>) => {
      state.errorModifiersUuids = action.payload
    },
    removeErrorModifiersUuid: (state, action: PayloadAction<string>) => {
      state.errorModifiersUuids = state.errorModifiersUuids.filter(uuid => uuid !== action.payload)
    },
    resetNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None
    },
    setNeedRedirectToProductPage: (state, action) => {
      state.needRedirectToProductPage = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getProductDetail.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductDetail.fulfilled, (state, action) => {
      state.product = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductDetail.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      if (state.product) state.product.isFavorite = action.payload.isFavourite;
    });
  },
});

export const { changeChosenModifiers, setNeedRedirectToProductPage, resetNetworkStatus, setErrorModifiersUuids, removeErrorModifiersUuid, clearChosenModifiersAndProduct } = productDetailSlice.actions

export const productState = (state: RootState) => state[productDetailSlice.name];
