import { ClientCatalogProductDetailResponse } from '@teleport/schemas-protobuf';
import { DeliveryActionType, IClientCatalogProductFull } from '../slices/productDetailSlice';


export const ProductTranslator = {
  fromStatusResponse(response: ClientCatalogProductDetailResponse) {
    const deliveryAction = {
      type: undefined,
      value: undefined,
    };

    switch (response.product.deliveryAction.case) {
      case 'deliveryLink':
        deliveryAction.type = DeliveryActionType.Link;
        deliveryAction.value = response.product.deliveryAction.value;
        break;
      case 'deliveryRedeemCode':
        deliveryAction.type = DeliveryActionType.RedeemCode;
        deliveryAction.value = response.product.deliveryAction.value;
        break;
      case 'deliveryNoop':
        deliveryAction.type = DeliveryActionType.Noop;
        break;
      case undefined:
      default:
        console.warn('unknown product deliveryAction type');
    }

    const product: IClientCatalogProductFull = {
      uuid: response.product.uuid,
      title: response.product.title,
      description: response.product.description,
      imageUrls: response.product.imageUrls,
      isFavorite: response.product.isFavorite,
      price: {
        amount: Number(response.product.price.amount),
        discountEnabled: response.product.price.discountEnabled,
        oldAmount: Number(response.product.price.oldAmount),
        discountInPercent: Number(response.product.price.discountInPercent),
      },
      modifiers: response.product.modifiers.map(modifier => {
        return {
          modifierVariantUuid: modifier.modifierVariantUuid,
          priceImpact: Number(modifier.priceImpact)
        };
      }),
      recommendations: response.product.recommendations,
      categories: response.product.categories,
      deliveryAction,
    };
    return product;
  },
};
