import { ProductsListTranslator } from 'src/redux/translators/productsListTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';

interface IProductListPayload {
  selectionUuid: string;
  selectionLocation: 'productPage' | 'mainPage';
}

export const getSelectionProducts = createAppAsyncThunk(
  'productList',
  async (payload: IProductListPayload, thunkAPI) => {
    const { selectionUuid, selectionLocation } = payload;

    const result = await thunkAPI.extra.portApi.clientCatalogProductList({
      filters: [{ filterDefaultState: { case: 'selectionFilter', value: { selectionUuid } } }],
      sorting: 0,
    });

    return {
      selectionUuid,
      products: ProductsListTranslator.fromProductsResponse(result),
      selectionLocation,
    };
  },
);
