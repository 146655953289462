import { createAppAsyncThunk } from '../../../hooks/redux';
import { CartTranslator } from '../../translators/cartTranslator';
import { IClientModifier } from 'src/redux/slices/modifiersSlice';

export interface IProductToCardParams {
  productUuid: string;
  chosenModifiers: IClientModifier[]
}

export const productToCardAppend = createAppAsyncThunk('cart/productToCardAppend', async (params: IProductToCardParams, thunkAPI) => {
  const product = CartTranslator.toProductToCardAppendRequest(params);
  await thunkAPI.extra.portApi.productToCartAppend({ product });
  return CartTranslator.toCartProductWidthId(product)
});
