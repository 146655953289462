import { createAppAsyncThunk } from '../../hooks/redux';
import {CheckoutTranslator} from '../translators/checkoutTranslator';
import { IClientModifier } from '../slices/modifiersSlice';

export interface IDirectCheckoutParams {
  product: {
    productUuid: string,
    quantity: number,
    chosenModifiers: IClientModifier[]
  };
  formData: {
    name: string,
    phone: string,
    comment?: string,
  }
}

export const directCheckout = createAppAsyncThunk('checkout/directCheckout', async (params: IDirectCheckoutParams, thunkAPI) => {
  const state = thunkAPI.getState();
  const productEnabled2_2 = state.config.config.toggles.productEnabled2_2
  const deliveryType = state.config.config.deliveryType;
  const requestData = CheckoutTranslator.toCheckoutRequest(params, deliveryType, productEnabled2_2);
  return await thunkAPI.extra.portApi.directCheckout(requestData);
});


