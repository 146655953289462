import { IDirectCheckoutParams } from '../api/directCheckout';
import { CheckoutForm_Base, DeliveryType } from '@teleport/schemas-protobuf';
import {
  CheckoutForm,
  CheckoutForm_Request
} from '@teleport/schemas-protobuf/port/v1/port_order_pb';
import { ICartCheckoutParams } from '../api/cart/cartCheckout';

export const CheckoutTranslator = {
  toCheckoutRequest(params: IDirectCheckoutParams | ICartCheckoutParams, deliveryType: DeliveryType, productEnabled2_2: boolean) {
    const { phone, name, comment } = params.formData;
    const deliveryCase = DeliveryType[deliveryType].toLowerCase();
    const checkoutForm = new CheckoutForm({checkoutForm: {value: undefined, case: undefined}});

    if (productEnabled2_2) {
      checkoutForm.checkoutForm.case = 'base';
      checkoutForm.checkoutForm.value = new CheckoutForm_Base({ phone, fullName: name, checkoutNotes: comment })
    } else if (deliveryCase === 'request') {
      checkoutForm.checkoutForm.case = deliveryCase;
      checkoutForm.checkoutForm.value = new CheckoutForm_Request({ phone, fullName: name })
    }

    if ('product' in params) {
      return {
        product: {
          productUuid: params.product.productUuid,
          quantity: params.product.quantity,
          chosenModifiers: params.product.chosenModifiers
        },
        checkoutForm
      };
    }

    return {
      checkoutForm
    }
  },
};
