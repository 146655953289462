import { IGetProductsListParams } from '../api/getProductsList';
import { IClientCatalogProductShort } from '../slices/productsListSlice';
import {
  ClientCatalogProductListResponse, RootCategory, SubCategory, Filter,
} from '@teleport/schemas-protobuf';
import { IFilter } from '../slices/filterSlice';
import { FilterTranslator } from './filterTranslator';

export const ProductsListTranslator = {
  fromProductsResponse(response: ClientCatalogProductListResponse): IClientCatalogProductShort[] {
    const products = response.products.map(product => {
      return {
        uuid: product.uuid,
        title: product.title,
        previewImageUrls: product.previewImageUrls,
        isFavorite: product.isFavorite,
        price: {
          // todo fix bigint from codegen
          amount: Number(product.price?.amount ?? 0),
          discountEnabled: product.price?.discountEnabled,
          oldAmount: Number(product.price?.oldAmount ?? 0),
          discountInPercent: Number(product.price?.discountInPercent ?? 0),
        },
      };
    });
    return products;
  },

  toRequestProductsByCategory(categoryId: string) {
    const categoryForFilter = new RootCategory({
      uuid: categoryId,
      title: 'request',
      subCategoryEnabled: false,
    })

    const filter = FilterTranslator.toCategoryFilter(categoryForFilter);

    const requestParams: IGetProductsListParams = {
      filters: [filter],
      sorting: 0,
    };

    return requestParams;
  },

  toRequestProductsBySubCategory(categoryUuid: string, subCategoryUuid: string) {
    const subCategoryForFilter = new SubCategory({categoryUuid, title: 'request', uuid: subCategoryUuid});

    const filter: IFilter = FilterTranslator.toSubCategoryFilter(subCategoryForFilter)

    const requestParams: IGetProductsListParams = {
      filters: [filter],
      sorting: 0,
    };

    return requestParams;
  },

  toRequestProductsBySelection(selectionUuid: string) {
    const filter = FilterTranslator.toSelectionFilter(selectionUuid);
    const requestParams: IGetProductsListParams = {
      filters: [filter],
      sorting: 0,
    };
    return requestParams;
  },

  toRequestProductsByFavorites() {
    const favoriteFilter = new Filter({
      filterDefaultState: {
        case: 'favoriteFilter',
        value: {},
      },
    });
    return favoriteFilter;
  },
};
