import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Sorting } from '@teleport/schemas-protobuf';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { createAppAsyncThunk } from '../../hooks/redux';
import { IClientCatalogProductShort } from './productsListSlice';
import { ProductsListTranslator } from '../translators/productsListTranslator';

interface FavouritesState {
  products: IClientCatalogProductShort[];
  productUuids: string[];
  networkStatus: NetworkStatus;
  productsToFavoriteStatus: NetworkStatus;
}

export const getFavouritesProducts = createAppAsyncThunk<IClientCatalogProductShort[]>(
  'favourites/fetchProducts',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portApi.clientCatalogProductList({
      filters: [ProductsListTranslator.toRequestProductsByFavorites()],
      sorting: Sorting.UNSPECIFIED,
    });

    return ProductsListTranslator.fromProductsResponse(result);
  },
);

export const productToFavoriteBind = createAppAsyncThunk(
  'favourites/productToFavoriteBind',
  async (
    {
      productUuids,
      currentUuid,
      isFavourite,
    }: { productUuids: string[]; currentUuid: string; isFavourite: boolean },
    thunkAPI,
  ) => {
    await thunkAPI.extra.portApi.productToFavoriteBind({ productUuids });
    return { currentUuid, isFavourite };
  },
);
export const productToFavoriteUnbind = createAppAsyncThunk(
  'favourites/productToFavoriteUnbind',
  async (
    {
      productUuids,
      currentUuid,
      isFavourite,
    }: { productUuids: string[]; currentUuid: string; isFavourite: boolean },
    thunkAPI,
  ) => {
    await thunkAPI.extra.portApi.productToFavoriteUnbind({ productUuids });
    return { currentUuid, isFavourite };
  },
);

const initialState: FavouritesState = {
  products: [],
  productUuids: [],
  networkStatus: NetworkStatus.None,
  productsToFavoriteStatus: NetworkStatus.None,
};

export const favouritesSlice = createSlice({
  name: 'favourites',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFavouritesProducts.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getFavouritesProducts.fulfilled, (state, action) => {
      state.products = action.payload;
      state.productUuids = action.payload.map(el => el.uuid);
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getFavouritesProducts.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToFavoriteBind.pending, state => {
      state.productsToFavoriteStatus = NetworkStatus.Loading;
    });
    builder.addCase(productToFavoriteBind.rejected, state => {
      state.productsToFavoriteStatus = NetworkStatus.Failed;
    });
    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      state.productsToFavoriteStatus = NetworkStatus.Done;

      const { currentUuid, isFavourite } = action.payload;
      if (isFavourite) {
        state.productUuids.push(currentUuid);
      } else {
        state.productUuids = state.productUuids.filter(el => el !== currentUuid);
        state.products = state.products.filter(el => el.uuid !== currentUuid);
      }
    });
    builder.addCase(productToFavoriteUnbind.pending, state => {
      state.productsToFavoriteStatus = NetworkStatus.Loading;
    });
    builder.addCase(productToFavoriteUnbind.rejected, state => {
      state.productsToFavoriteStatus = NetworkStatus.Failed;
    });
    builder.addCase(productToFavoriteUnbind.fulfilled, (state, action) => {
      state.productsToFavoriteStatus = NetworkStatus.Done;

      const { currentUuid } = action.payload;
      state.productUuids = state.productUuids.filter(el => el !== currentUuid);
      state.products = state.products.filter(el => el.uuid !== currentUuid);
    });
  },
});

export const favouritesState = (state: RootState) => state[favouritesSlice.name];
