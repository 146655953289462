import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { InfoblockRules } from './infoBlock.style';
import { useTelegram } from 'src/utils/telegramProvider';
import { ITextBlock } from 'src/redux/slices/mainSlice';


interface IProps {
  textBlock: ITextBlock
}

const InfoBlock: FC<IProps> = memo(function InfoBlock(props) {
  const { textBlock } = props;
  const { theme } = useTheme();
  const { webApp } = useTelegram();
  const { css } = useStyle(InfoblockRules, { accentColor: textBlock?.accentColor });

  function handleButtonClick() {
    webApp.openLink(textBlock?.button.clickUrl, { try_instant_view: true });
  }

  if (!textBlock) return null

  return (
    <div className={css.wrapper}>
      <Text text={textBlock.title} fontWeight={700} extend={css.title} mod="title" />
      <div className={css.content}>
        <Text
          text={textBlock.text}
          fontSize={12}
          fontWeight={500}
          lineHeight="16px"
          extend={css.text}
          mod="text"
        />
        {textBlock.button?.enabled  && (
          <Button
            text={textBlock.button.text}
            propsStyles={{
              width: '100%',
              height: 42,
              background: textBlock.accentColor ? `rgba(${theme.colorRgbWhite}, 0.2)` : theme.colorBlack,
            }}
            extend={css.button}
            onClick={handleButtonClick}
          />
        )}
      </div>
    </div>
  );
});

export default memo(InfoBlock);
