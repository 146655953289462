import { createSlice } from '@reduxjs/toolkit';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { RootState } from '../store';
import { ProductsListTranslator } from '../translators/productsListTranslator';
import { getProductsList } from '../api/getProductsList';
import { productToFavoriteBind } from './favouritesSlice';
import { IPrice } from 'src/types/price';

interface IProducts {
  products: IClientCatalogProductShort[];
  networkStatus: NetworkStatus;
}
export interface IClientCatalogProductShort {
    uuid: string;
    title: string;
    previewImageUrls: string[];
    isFavorite: boolean;
    price?: IPrice;
}

const initialState: IProducts = {
  products: [],
  networkStatus: NetworkStatus.None,
};

export const productsSlice = createSlice({
  name: 'productsList',
  initialState,
  reducers: {
    resetProducts: state => {
      state.products = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(getProductsList.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductsList.fulfilled, (state, action) => {
      state.products = ProductsListTranslator.fromProductsResponse(action.payload).reverse();
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductsList.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      const { currentUuid, isFavourite } = action.payload;
      const product = state.products.find(el => el.uuid === currentUuid);
      if (product) {
        product.isFavorite = isFavourite;
      }
    });
  },
});

export const { resetProducts } = productsSlice.actions

export const productsState = (state: RootState) => state[productsSlice.name];
