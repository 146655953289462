import { createSlice } from '@reduxjs/toolkit';
import { ISelectionWithProducts } from 'src/types/selections';
import { BlockType, getStatus } from './mainSlice';
import { getProductDetail } from './productDetailSlice';
import { RootState } from '../store';
import { getSelectionProducts } from '../api/selections/getSelectionProducts';
import { NetworkStatus } from 'src/utils/connect/connectConstant';

interface SelectionsState {
  mainPageSelections: ISelectionWithProducts[];
  productPageSelections: ISelectionWithProducts[];
}

const initialState: SelectionsState = {
  mainPageSelections: [],
  productPageSelections: [],
};

export const selectionsSlice = createSlice({
  name: 'selections',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStatus.fulfilled, (state, action) => {
      const { blocks, order } = action.payload;
      order.length > 0 && order.forEach(id => {
        const block = blocks[id];
        switch (block?.type) {
          case BlockType.SelectionBlock: 
            return  state.mainPageSelections.push({
              ...block,
              networkStatus: NetworkStatus.None,
              products: [],
            });
          default: return null
        }
      })

    });

    builder.addCase(getProductDetail.fulfilled, (state, action) => {
      const { recommendations } = action.payload;
      const selectionBlocks = recommendations.map(el => ({
        ...el,
        networkStatus: NetworkStatus.None,
        products: [],
      }));
      state.productPageSelections = selectionBlocks;
    });

    builder.addCase(getSelectionProducts.fulfilled, (state, action) => {
      const { selectionUuid, products, selectionLocation } = action.payload;
      if (selectionLocation === 'mainPage') {
        state.mainPageSelections = state.mainPageSelections.map(selection => {
          if (selection.uuid === selectionUuid) {
            return { ...selection, products, networkStatus: NetworkStatus.Done };
          } else {
            return selection;
          }
        });
      }
      if (selectionLocation === 'productPage') {
        state.productPageSelections = state.productPageSelections.map(selection => {
          if (selection.uuid === selectionUuid) {
            return { ...selection, products, networkStatus: NetworkStatus.Done };
          } else {
            return selection;
          }
        });
      }
    });
  },
});

export const {} = selectionsSlice.actions;
export const selectionsState = (state: RootState) => state[selectionsSlice.name];
export const selectionsProductPage = (state: RootState) =>
  state[selectionsSlice.name].productPageSelections;
