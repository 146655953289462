import { createAppAsyncThunk } from '../../../hooks/redux';
import { CheckoutTranslator } from '../../translators/checkoutTranslator';

export interface ICartCheckoutParams {
  formData: {
    name: string,
    phone: string,
    comment?: string,
  }
}

export const cartCheckout = createAppAsyncThunk('checkout/cartCheckout', async (params: ICartCheckoutParams, thunkAPI) => {
  const state = thunkAPI.getState();
  const productEnabled2_2 = state.config.config.toggles.productEnabled2_2
  const deliveryType = state.config.config.deliveryType;
  const requestData = CheckoutTranslator.toCheckoutRequest(params, deliveryType, productEnabled2_2)
  return await thunkAPI.extra.portApi.cartCheckout(requestData);
});
