import { FC, memo, useEffect, useMemo } from 'react';
import { Button } from 'src/components/UI/Button/Button';
import { Stories } from 'src/blocks/Stories/Stories';
import Wrapper from 'src/components/Wrapper/Wrapper';
import InfoBlock from 'src/components/InfoBlock/InfoBlock';
import Advertising from 'src/components/Advertising/Advertising';
import Watermark from 'src/components/Watermark/Watermark';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import Header from 'src/components/Header/Header';
import Container from 'src/components/Container/Container';
import { useStyle } from 'src/utils/theme/useStyle';
import { mainPageRules } from './main.style';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { BlockType, getStatus, mainState } from '../../redux/slices/mainSlice';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import useLoader from 'src/hooks/useLoader';
import StoreNotWorking from 'src/components/StoreNotWorking/StoreNotWorking';
import Selection from 'src/components/Selection/Selection';
import { selectionsState } from '../../redux/slices/selectionsSlice';
import useBackButton from 'src/hooks/useBackButton';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import { StoriesSkeleton } from '../../blocks/Stories/StoriesSkeleton';
import { InfoBlockSkeleton } from '../../components/InfoBlock/InfoBlockSkeleton';
import { AdvertisingSkeleton } from '../../components/Advertising/AdvertisingSkeleton';
import SelectionSkeleton from '../../components/Selection/SelectionSkeleton';

export const MainPage: FC = memo(function MainPage() {
  const { css } = useStyle(mainPageRules);
  const { t } = useTranslation();
  const { order, blocks, hasMaintenance, maintenance, networkStatus } = useAppSelector(mainState);
  const { mainPageSelections } = useAppSelector(selectionsState);
  const dispatch = useAppDispatch();
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();

  useEffect(() => {
    BackButton.hide();
  }, [BackButton]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getStatus());
    }
  }, [dispatch, networkStatus]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  // container
  // titleStyle
  // textStyle
  // хранить шаг в локалсторадже

  const content = useMemo(() => {
    if (networkStatus === NetworkStatus.Loading) {
      return (
        <>
          <StoriesSkeleton />
          <Wrapper>
            <Container>
              <InfoBlockSkeleton />
              <InfoBlockSkeleton />
            </Container>
          </Wrapper>
          <Wrapper>
            <AdvertisingSkeleton />
          </Wrapper>
          <Wrapper>
            <SelectionSkeleton />
          </Wrapper>
        </>
      )
    }


    return (
      <>
        {
          order.length > 0 && order.map(id => {
            const block = blocks[id];
            switch (block?.type) {
              case BlockType.StoriesBlock: 
                return (
                  <Stories 
                    key={'stories'}
                    propsStyles={{ paddingBottom: 20, paddingTop: 8 }}
                    storiesBlock={block}
                  />
                )
              case BlockType.SliderBlock: 
                return (
                  <Wrapper key={id}>
                    <Advertising sliderBlock={block} />
                  </Wrapper>
                )
              case BlockType.TextBlock: 
                return (
                  <Wrapper key={id}>
                    <Container>
                      <InfoBlock textBlock={block}/>
                    </Container>
                  </Wrapper>
                )
              case BlockType.SelectionBlock:  
                const productsSelection = blocks[id]
                  ? mainPageSelections.find(selection => selection.uuid === blocks[id].uuid)
                  : null;
                if (productsSelection) return (
                  <Wrapper key={productsSelection.uuid}>
                    <Selection selectionData={productsSelection} location="mainPage" extend={css.collection} />
                  </Wrapper>
                )
              default: 
                return null
            }
          })
        }
        <Wrapper key={'watermark'}>
          <Watermark />
        </Wrapper>
      </>
    )
  }, [
    css,
    blocks,
    mainPageSelections,
    networkStatus,
    order
  ])

  if (hasMaintenance) {
    return (
      <StoreNotWorking reasonText={maintenance.reasonText} supportLink={maintenance.supportLink} />
    );
  }

  return (
    <Layout
      header={<Header />}
      footer={
        <Footer>
          <Button href="/catalog" text={t('mainPage.catalog')} propsStyles={{ width: '100%', height: 62 }} />
        </Footer>
      }
    >
      {content}
    </Layout>
  );
});
