import { FC, memo, useMemo } from 'react';
import { ModificatorOptions } from 'src/components/Modificators/ModificatorOptions/ModificatorOptions';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModificatorToggler } from 'src/components/Modificators/ModificatorToggler/ModificatorToggler';
import { ModificatorList } from 'src/components/Modificators/ModificatorList/ModificatorList';
import { productModificatorsRules } from './productModificators.style';
import { IModifier, IModifierType, IProductModifierImpact } from 'src/redux/slices/modifiersSlice';

interface IProps {
  modifiers: IModifier[]
  variants: IProductModifierImpact[]
}

export interface ICompoundVariant {
  uuid: string;
  title: string;
  priceImpact: number;
}
export interface ICompoundModifier {
  uuid: string;
  uiType: IModifierType;
  title: string;
  descriptionLink: string;
  affectsPrice: boolean;
  variants: ICompoundVariant[]
}

export const ProductModificators: FC<IProps> = memo(function ProductModificators(props) {
  const { css } = useStyle(productModificatorsRules);
  const { modifiers, variants } = props

  const renderModifiersByType = useMemo(() => {
    if (!modifiers?.length ||  !variants?.length ) return null
    const compoundModifiers: ICompoundModifier[] = modifiers.filter(modifier => {
      return modifier.variants?.some(variant => 
        variants.some(element => element.modifierVariantUuid === variant.modifierVariantUuid)
      ) ?? false
    }).map(modifier => {
      const variantsUuids = variants.map(v => v.modifierVariantUuid)
      const newModifierVariants = modifier.variants.filter(variant => variantsUuids.includes(variant.modifierVariantUuid)).map(variant => {
        const foundVariant = variants.find(v => v.modifierVariantUuid === variant.modifierVariantUuid);
        return {
          uuid: variant.modifierVariantUuid,
          title: variant.modifierVariantTitle,
          priceImpact: foundVariant? foundVariant.priceImpact : 0
        }
      })
      return {
        uuid: modifier.uuid,
        uiType: modifier.uiType,
        title: modifier.title,
        descriptionLink: modifier.descriptionLink,
        affectsPrice: modifier.affectsPrice,
        variants: newModifierVariants
      }
    })

    const optionsModifiers = compoundModifiers
      .filter(modifier => modifier.uiType === 2)
      .map(modifier => (
        <ModificatorOptions modifier={modifier} key={modifier.uuid} extend={css.modificator} />
      ));

    const togglerModifiers = compoundModifiers
      .filter(modifier => modifier.uiType === 1)
      .map(modifier => (
        <ModificatorToggler modifier={modifier}  key={modifier.uuid} extend={css.modificator} />
      ));

    const listModifiers = compoundModifiers
      .filter(modifier => modifier.uiType === 3)
      .map(modifier => (
        <ModificatorList key={modifier.uuid}  modifier={modifier} extend={css.modificator} />
      ));

    return (
      <>
        {optionsModifiers}
        {togglerModifiers}
        {listModifiers}
      </>
    );
  }, [css, modifiers, variants]);
  
  
  return (
    <div className={css.modificators}>
      {renderModifiersByType}
    </div>
  );
});
