import { ModifierListResponse, ModifierType } from '@teleport/schemas-protobuf';
import { IModifier, IModifierType } from '../slices/modifiersSlice';

function mapModifierType(modifierType: ModifierType): IModifierType {
  switch (modifierType) {
    case ModifierType.UNSPECIFIED:
      return IModifierType.Unspecified;
    case ModifierType.TOGGLE:
      return IModifierType.Toggle;
    case ModifierType.CHIPS:
      return IModifierType.Chips;
    case ModifierType.LIST:
      return IModifierType.List;
    default:
      return IModifierType.Unspecified;
  }
}


export const ModifiersTranslator = {
  formModifierForResponse(modifiersListResponse: ModifierListResponse) {
    const modifiersList:IModifier[]   =  modifiersListResponse.modifiers.map(modifier => {
      return {
        uuid: modifier.uuid,
        uiType:mapModifierType(modifier.uiType),
        title: modifier.title,
        descriptionLink: modifier.descriptionLink,
        affectsPrice: modifier.affectsPrice,
        variants: modifier.variants.map(variant => {
          return {
            modifierVariantUuid: variant.uuid,
            modifierVariantTitle: variant.title,
          }
        }),
      }
    })
    return modifiersList
  }
}
