import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { MainTranslator } from '../translators/mainTranslator';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import { createAppAsyncThunk } from '../../hooks/redux';

export interface ITextBlockButton {
  enabled: boolean;
  text: string;
  clickUrl: string;
}

export interface ITextBlock {
  uuid: string;
  title: string;
  text: string;
  accentColor: boolean;
  button?: ITextBlockButton;
  type: BlockType.TextBlock
}

export interface IStory {
  uuid: string;
  text: string;
  imageUrl: string;
  clickUrl: string;
}

export interface IStoryItem {
  story?: IStory;
  viewed: boolean;
}

export interface IStoriesBlock {
  items: IStoryItem[];
  type: BlockType.StoriesBlock
  uuid?: string // Сделал optional type что бы обращаться к юиду историй в компоненте mainPage
}

export interface ISliderItem {
  imageUrl: string;
  clickUrl: string;
}

export interface ISliderBlock {
    uuid: string;
    items: ISliderItem[];
    type: BlockType.SliderBlock
}

export interface ISelectionBlockVisibility {
  showOnMain: boolean;
  showInRecommendations: boolean;
}

export interface ISelectionBlock {
  title: string;
  uuid: string;
  visibility?: ISelectionBlockVisibility;
  enabled: boolean;
  type?: BlockType.SelectionBlock
}

export type IUIBlocks = ITextBlock | IStoriesBlock | ISliderBlock | ISelectionBlock

export interface IMainStateData {
  order: string[],
  blocks: Record<string, IUIBlocks> ;
  hasMaintenance: boolean;
  maintenance: {
    reasonText: string;
    supportLink: string;
  };
}

type IMainState = IMainStateData & {
  networkStatus: NetworkStatus;
}

export enum BlockType {
  TextBlock = 'textBlock',
  StoriesBlock = 'storiesBlock',
  SliderBlock = 'sliderBlock',
  SelectionBlock = 'selectionBlock'
}

const initialState: IMainState = {
  order: [],
  blocks: {},
  hasMaintenance: false,
  maintenance: {
    reasonText: undefined,
    supportLink: undefined,
  },
  networkStatus: NetworkStatus.None,
};

export const getStatus = createAppAsyncThunk('main/status', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portApi.status({});
  return MainTranslator.fromStatusResponse(result);
});

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getStatus.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      const { order, blocks, hasMaintenance, maintenance } = action.payload;
      state.hasMaintenance = hasMaintenance;
      state.maintenance = maintenance;
      state.blocks = blocks;
      state.order = order,
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStatus.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const {} = mainSlice.actions;
export const mainState = (state: RootState) => state[mainSlice.name];
