import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { infoRules } from './info.style';
import { Icon } from 'src/components/Icon/Icon';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  href: string;
}

export const Info: FC<IProps> = memo(function Info(props) {
  const { href } = props;
  const { css } = useStyle(infoRules);
  const { webApp } = useTelegram();

  const openLink = () => {
    webApp.openLink(href, { try_instant_view: true });
  }
  return (
    <span onClick={openLink} className={css.info}>
      <Icon name="info" />
    </span>
  );
});
