import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModificatorListRuleKeys {
  btnOpen: {};
  modificator: {};
  rightBlock: {};
  error: {};
  modifierButton: {};
}

export type IModificatorListRules = IRuleFn<IModificatorListRuleKeys>;

const modificatorListRuleFn: IModificatorListRules = props => {
  const { colorRed } = props.theme;

  return {
    modificator: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '9px',
    },
    btnOpen: {
      borderRadius: 0,
    },
    rightBlock: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },
    error: {
      color: colorRed,
      fontSize: 12,
      fontWeight: 500,
      paddingTop: 4,
    },
    modifierButton: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2
    }
  };
};

export const modificatorListRules = createCachedStyle(modificatorListRuleFn);
