import { FC, memo, useEffect } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import { useStyle } from 'src/utils/theme/useStyle';
import { MakeOrderRules } from './MakeOrder.style';
import useLoader from 'src/hooks/useLoader';
import { MakeOrderForm } from 'src/components/MakeOrderForm/MakeOrderForm';
import Container from 'src/components/Container/Container';
import Text from 'src/components/UI/Text/Text';
import { useNavigate } from 'react-router-dom';
import useBackButton from 'src/hooks/useBackButton';
import {useTranslation} from '../../utils/i18n/hooks/useTranslation';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { checkoutFormGet, checkoutState } from '../../redux/slices/checkoutSlice';

export const MakeOrder: FC = memo(function MainPage() {
  const { css } = useStyle(MakeOrderRules);
  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const {t} = useTranslation();
  const { checkoutForm, formNetworkStatus } = useAppSelector(checkoutState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (formNetworkStatus === NetworkStatus.None) {
      dispatch(checkoutFormGet());
    }
  }, [dispatch, formNetworkStatus]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    hideLoader();
  });

  return (
    <Layout
      header={
        <Container extend={css.header} paddingBottom={12} paddingTop={16}>
          <Text text={t('makeOrder.checkout')} mod="title" />
        </Container>
      }
    >
      {
        formNetworkStatus !== NetworkStatus.Loading && formNetworkStatus !== NetworkStatus.None && (
          <MakeOrderForm
            customerInitialData={{
              name: checkoutForm?.value?.fullName,
              phone: checkoutForm?.value?.phone,
              comment: '',
            }}
            commentPlaceholder={checkoutForm?.case === 'base' ? checkoutForm?.value.checkoutNotes : t('makeSingleOrder.writeAComment')}
          />
        )
      }
    </Layout>
  );
});
