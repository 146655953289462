import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { cartGet } from '../api/cart/cartGet';
import { productToCardAppend } from '../api/cart/productToCardAppend';
import { removeProductFromCart } from '../api/cart/removeProductFromCart';
import { changeQuantityProduct } from '../api/cart/changeQuantityProduct';
import { productToFavoriteBind, productToFavoriteUnbind } from './favouritesSlice';
import { cartCheckout } from '../api/cart/cartCheckout';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { IClientModifier } from './modifiersSlice';
import { IPromocode } from '../../types/promocode';
import { IPrice } from '../../types/price';

export interface ICartPrice {
  productTotal?: IPrice;
  promocodeDiscount: number;
  cartTotal: number;
}

export interface ICartProduct {
  productUuid: string;
  quantity: number;
  chosenModifiers: IClientModifier[];
  productTitle: string;
  productImageUrl: string;
  price?: IPrice;
  productIsFavorite: boolean;
}

export interface ICartProductWidthId extends ICartProduct{
  id: string
}

export enum TypeOfDiscount {
  Unspecified = '',
  PriceImpact = 'priceImpact',
  PercentImpact = 'percentImpact',
}

interface CartState {
  products: ICartProductWidthId[];
  minPrice: number;
  total?: ICartPrice
  promoCode?: IPromocode;
  errorPromocodeMessage?: string;
  errorCartAppend?: string;
  errorChangeQuantity?: string;
  networkStatus: NetworkStatus;
  networkStatusCardAppend: NetworkStatus,
  networkStatusRemoveProduct: NetworkStatus;
  networkStatusChangeQuantityProduct: NetworkStatus;
  networkStatuspromocodeAcquire: NetworkStatus;
}

export const promocodeAcquireRequest = createAppAsyncThunk('cart/promocodeAcquireRequest', async (promocodeCodename: string, thunkAPI) => {
  const promocodeAcquireResponse = await thunkAPI.extra.portApi.promocodeAcquire({
    promocodeCodename: promocodeCodename
  })
  return promocodeAcquireResponse
});
export const promocodeUnacquireRequest = createAppAsyncThunk('cart/promocodeUnacquireRequest', async (promocodeCodename: string, thunkAPI) => {
  try {
    const promocodeUnacquireResponse = await thunkAPI.extra.portApi.promocodeUnacquire({
      promocodeCodename: promocodeCodename
    })
    return promocodeUnacquireResponse
  }catch (e) {
    return e.message
  }
});

const initialState: CartState = {
  promoCode: undefined,
  errorPromocodeMessage: undefined,
  errorCartAppend: undefined,
  errorChangeQuantity: undefined,
  minPrice: 0,
  products: [],
  total: undefined,
  networkStatus: NetworkStatus.None,
  networkStatusCardAppend: NetworkStatus.None,
  networkStatusRemoveProduct: NetworkStatus.None,
  networkStatusChangeQuantityProduct: NetworkStatus.None,
  networkStatuspromocodeAcquire: NetworkStatus.None,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCartMinPrice: (state, action: PayloadAction<number>) => {
      if (action.payload > 0) state.minPrice = action.payload
    },
    resetNetworkStatuspromocodeAcquire: state => {
      state.networkStatuspromocodeAcquire = NetworkStatus.None
      state.errorPromocodeMessage = undefined
    },
    resetCartNetworkStatus: (state, { payload }: PayloadAction<'networkStatusCardAppend' | 'networkStatusChangeQuantityProduct' | 'networkStatusRemoveProduct'>) => {
      state[payload] = NetworkStatus.None
    }
  },
  extraReducers: builder => {
    builder.addCase(cartGet.fulfilled, (state, {payload}) => {
      state.products = payload.products;
      state.total = payload.total;
      state.promoCode = payload.promocode;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(cartGet.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(cartGet.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(productToCardAppend.pending, state => {
      state.networkStatusCardAppend = NetworkStatus.Loading;
    });
    builder.addCase(productToCardAppend.fulfilled, (state, action) => {
      state.networkStatusCardAppend = NetworkStatus.Done;
      const appendedProduct = action.payload;
      const currentItem = state.products.find(el => el.productUuid === appendedProduct.productUuid);
      if(currentItem) {
        currentItem.quantity += 1;
      } else {
        state.products.push(appendedProduct)
      }
    });
    builder.addCase(productToCardAppend.rejected, (state, action) => {
      state.networkStatusCardAppend = NetworkStatus.Failed;
      state.errorCartAppend = action.error.message;
    });
    //remove from cart
    builder.addCase(removeProductFromCart.rejected, state => {
      state.networkStatusRemoveProduct = NetworkStatus.Failed;
    });
    builder.addCase(removeProductFromCart.pending, state => {
      state.networkStatusRemoveProduct = NetworkStatus.Loading;
    });
    builder.addCase(removeProductFromCart.fulfilled, (state, action) => {
      const { id, total } = action.payload;
      state.networkStatusRemoveProduct = NetworkStatus.Done;
      state.products = state.products.filter(el => el.id !== id);
      state.total = total;
    });
    
    builder.addCase(changeQuantityProduct.pending, state => {
      state.networkStatusChangeQuantityProduct = NetworkStatus.Loading
    });
    builder.addCase(changeQuantityProduct.rejected, (state, action) => {
      state.networkStatusChangeQuantityProduct = NetworkStatus.Failed
      state.errorChangeQuantity = action.error.message;
    });
    builder.addCase(changeQuantityProduct.fulfilled, (state, action) => {
      state.networkStatusChangeQuantityProduct = NetworkStatus.Done
      const { id, action: quantityAction, total } = action.payload;
      const product = state.products.find(element => element.id === id);
      if (!product) return;
      if(quantityAction === 'increment') {
        product.quantity++;
      } else if(quantityAction === 'decrement') {
        if (product.quantity - 1 < 1) return;
        product.quantity--;
      }
      state.total = total;
    });

    builder.addCase(productToFavoriteBind.fulfilled, (state, action) => {
      state.products = state.products.map(el => {
        if (el.productUuid === action.payload.currentUuid) {
          return {...el, productIsFavorite: true};
        }
        return el;
      })
    })

    builder.addCase(productToFavoriteUnbind.fulfilled, (state, action) => {
      state.products = state.products.map(el => {
        if (el.productUuid === action.payload.currentUuid) {
          return {...el, productIsFavorite: false};
        }
        return el;
      })
    })

    builder.addCase(cartCheckout.fulfilled, () => {
      return initialState
    })

    builder.addCase(promocodeAcquireRequest.fulfilled, state => {
      state.networkStatuspromocodeAcquire = NetworkStatus.Done
      state.errorPromocodeMessage = undefined
    })
    builder.addCase(promocodeAcquireRequest.pending, state => {
      state.networkStatuspromocodeAcquire = NetworkStatus.Loading
      state.errorPromocodeMessage = undefined
    })
    builder.addCase(promocodeAcquireRequest.rejected, (state, action) => {
      state.networkStatuspromocodeAcquire = NetworkStatus.Failed
      state.errorPromocodeMessage = action.error?.message
    })
    builder.addCase(promocodeUnacquireRequest.fulfilled, state => {
      state.networkStatuspromocodeAcquire = NetworkStatus.Done
      state.errorPromocodeMessage = undefined
    })
    builder.addCase(promocodeUnacquireRequest.pending, state => {
      state.networkStatuspromocodeAcquire = NetworkStatus.Loading
      state.errorPromocodeMessage = undefined
    })
    builder.addCase(promocodeUnacquireRequest.rejected, (state, action) => {
      state.networkStatuspromocodeAcquire = NetworkStatus.Failed
      state.errorPromocodeMessage = action.error?.message
    })

  },
});

export const { setCartMinPrice, resetNetworkStatuspromocodeAcquire, resetCartNetworkStatus } = cartSlice.actions;
export const cartState = (state: RootState) => state[cartSlice.name];
