import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IProductsKeys {
  title: {};
  grid: {};
  card: {};
  subtitle: {};
  filtersWrapper: {};
  resultStroke: {};
  centerWrapper: {};
  wrapper: {};
  productsContainer: {};
  button: {};
  buttonSort: {};
  buttonFilter: {};
}

interface IProductsProps {
  sortIsActive?: boolean,
  filtersIsActive?: boolean;
}

export type IProducts = IRuleFn<IProductsKeys, IProductsProps>;

const ProductsRuleFn: IProducts = props => {
  const { colorBlack, colorWhite, colorGrey, colorDarkGrey, mainColor } = props.theme;
  const {filtersIsActive, sortIsActive} = props;

  return {
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    productsContainer: {
      flexGrow: 1
    },
    container: {
      paddingBottom: 16,
    },
    title: {
      color: colorBlack,
      fontSize: '22px',
      lineHeight: '26px',
      fontWeight: '800',
      marginBottom: '20px',
    },
    subtitle: {
      fontSize: '12px',
      lineHeight: '16px',
      marginBottom: '2px',
      color: mainColor,
      fontWeight: '600',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      columnGap: '7px',
      rowGap: '16px',
      paddingBottom: '16px',
    },
    card: {
      ':nth-child(3n)': {
        gridColumn: 'span 2',
      },
    },
    filtersWrapper: {
      padding: '0 16px 8px',
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      overflow: 'auto',
      
      '@media (pointer: coarse)': {
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }

    },
    button: {
      padding: 8,
      borderRadius: 8,
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      outline: 'none',
      transitionDuration: '0.2s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'color, background',
      '& svg': {
        width: 16,
        height: 16,
        transitionDuration: '0.2s',
        transitionTimingFunction: 'linear',
        transitionProperty: 'fill',
      },
    },
    buttonSort: {
      width: 32,
      height: 32,
      background: sortIsActive ? mainColor : colorGrey,
      '& svg': {
        fill: sortIsActive ? colorWhite : colorBlack
      },
    },
    buttonFilter: {
      columnGap: 4,
      background: filtersIsActive ? mainColor : colorGrey,
      color: filtersIsActive ? colorWhite : colorBlack,
      '& svg': {
        fill: filtersIsActive ? colorWhite : colorBlack
      },
    },
    resultStroke: {
      fontSize: 12, 
      lineHeight: '18px',
      fontWeight: 700,
      color: colorDarkGrey,
      padding: '8px 0',
    },
    centerWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  };
};

export const ProductsRules = createCachedStyle(ProductsRuleFn);
