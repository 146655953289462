import {Promocode} from '@teleport/schemas-protobuf';
import {TypeOfDiscount} from '../slices/cartSlice';
import {IPromocode} from '../../types/promocode';

export const PromoCodeTranslator = {
  toPromoCode(promoCode: Promocode): IPromocode {

    if (!promoCode) return undefined;

    let promoCodeCase: TypeOfDiscount;

    if (promoCode?.impact.case === 'percentImpact') {
      promoCodeCase = TypeOfDiscount.PercentImpact;
    } else if (promoCode?.impact.case === 'priceImpact') {
      promoCodeCase = TypeOfDiscount.PriceImpact;
    }
    return {
      uuid: promoCode.uuid,
      codename: promoCode.codename,
      impact: {
        value: Number(promoCode?.impact.value.value),
        type: promoCodeCase
      }
    }
  },
};
