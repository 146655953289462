import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { cartState, ICartProductWidthId } from '../../redux/slices/cartSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cartitemRules } from './cartitem.style';
import Text from '../UI/Text/Text';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { Price } from '../Price/Price';
import { removeProductFromCart } from '../../redux/api/cart/removeProductFromCart';
import { changeQuantityProduct } from '../../redux/api/cart/changeQuantityProduct';
import { Icon } from '../Icon/Icon';
import { favouritesState, productToFavoriteBind, productToFavoriteUnbind } from '../../redux/slices/favouritesSlice';
import { useTheme } from '../../utils/theme/useTheme';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import React from 'react';
import { Link } from 'react-router-dom';
import { IModifier } from 'src/redux/slices/modifiersSlice';

interface CommonProps {
  extend?: string;
  data: ICartProductWidthId;
  modifiers: IModifier[]
}

type IProps = CommonProps;

export const CartItem: FC<IProps> = memo(function CartItem(props) {
  const { extend, data, modifiers } = props;
  const { css } = useStyle(cartitemRules, { isFavorite: data.productIsFavorite });
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  const { products, networkStatusChangeQuantityProduct } = useAppSelector(cartState);
  const { productUuids: favoriteProductsUuids } = useAppSelector(favouritesState);
  const [reachedEnd, setReachedEnd] = useState(false);
  const swiperRef = useRef(null);
  const showDiscountPrice = data.price?.discountEnabled && Number(data.price.oldAmount);

  const loadingQuantity = networkStatusChangeQuantityProduct === NetworkStatus.Loading

  const filteredModifiers = modifiers
    .map(modifier => ({
      ...modifier,
      variants: modifier.variants.filter(variant => data.chosenModifiers.map(v => v.modifierVariantUuid).includes(variant.modifierVariantUuid))
    }))
    .filter(modifier => modifier.variants.length > 0);

  // Обработчик события при достижении конца слайдера
  const handleReachEnd = () => {
    setReachedEnd(true);
  };

  useEffect(() => {
    if (reachedEnd && swiperRef.current && swiperRef.current.slides?.length) {
      swiperRef.current.slideTo(swiperRef.current.slides?.length - 1, 0);
    }
  }, [reachedEnd]);

  const currentCount = data.quantity ? data.quantity : 1;

  const onClickFavoriteButton = useCallback(() => {
    if (favoriteProductsUuids.includes(data.productUuid)) {
      dispatch(
        productToFavoriteUnbind({
          productUuids: [data.productUuid],
          currentUuid: data.productUuid,
          isFavourite: false,
        }),
      );
    } else {
      dispatch(
        productToFavoriteBind({
          productUuids: [...favoriteProductsUuids, data.productUuid],
          currentUuid: data.productUuid,
          isFavourite: true,
        }),
      );
    }
  }, [favoriteProductsUuids, dispatch, data])

  const onClickRemoveProduct = useCallback(() => {
    dispatch(removeProductFromCart({ products, deletedUuid: data.productUuid, id: data.id }));
  }, [dispatch, products, data])

  const incrementProductCount = useCallback(() => {
    dispatch(changeQuantityProduct({action: 'increment', products, productUuid: data.productUuid, id: data.id }));
  }, [dispatch, data.productUuid, products, data.id])

  const decrementProductCount = useCallback(() => {
    const item = products.find(el => el.id === data.id);
    if (item.quantity <= 1) {
      onClickRemoveProduct()
      return;
    }
    dispatch(changeQuantityProduct({action: 'decrement', products, productUuid: data.productUuid, id: data.id }));
  }, [dispatch, data.productUuid, products, onClickRemoveProduct, data.id])


  return (
    <Swiper
      ref={swiperRef}
      className={`${css.sw_container} ${extend}`}
      spaceBetween={16}
      slidesPerView="auto"
      initialSlide={1}
      speed={100}
      onReachEnd={handleReachEnd}
    >
      <SwiperSlide className={`${css.slide_btn}`}>
        <button className={css.favoriteButton} onClick={onClickFavoriteButton}>
          <Icon name="heart" />
        </button>
      </SwiperSlide>
      <SwiperSlide>
        <div className={[css.ci_wrp].join('')}>
          <Link to={`/product/${data.productUuid}`} className={css.ci_img}>
            <ImageComponent src={data.productImageUrl ? data.productImageUrl : ''}/>
          </Link>
          <div className={css.info}>
            <div>
              <Link to={`/product/${data.productUuid}`}>
                <Text
                  text={data.productTitle}
                  mod="text"
                  fontSize={14}
                  lineHeight={'16px'}
                  fontWeight={800}
                  extend={css.title}
                />
              </Link>

              {
                filteredModifiers?.length > 0 && (
                  <div className={css.modifiers}>
                    {filteredModifiers.map(modifier => {
                      return (
                        <span key={modifier.uuid} className={css.modifierName}>{modifier.title}{' '}{
                          modifier.variants.map((variant, i) => {
                            return (
                              <React.Fragment key={variant.modifierVariantUuid}>{i !== 0 && ' ,'} {<span  className={css.modifierValue}>{variant.modifierVariantTitle}</span>}</React.Fragment>
                            )
                          })
                        }<span className={css.indent} /></span>
                      )
                    })}
                  </div>
                )
              }
            </div>

            <div className={css.info_bottom}>
              <div className={css.calc}>
                <button
                  onClick={decrementProductCount}
                  className={css.calc_btn}
                  disabled={loadingQuantity}
                >
                  <MinusIcon/>
                </button>
                <span>{currentCount >= 1000 ? '999+' : currentCount}</span>
                <button
                  onClick={incrementProductCount}
                  className={css.calc_btn}
                  disabled={loadingQuantity}
                >
                  <PlusIcon/>
                </button>
              </div>
              <div className={css.priceWrapper}>
                {showDiscountPrice &&
                  <Price
                    amount={Number(data.price.oldAmount) * currentCount}
                    fontWeight={700}
                    fontSize={12}
                    lineHeight={'18px'}
                    hasSymbol={false}
                    oldPrice={true}
                    color={theme.colorDarkGrey}
                  />
                }
                <Price
                  amount={Number(data.price?.amount) * currentCount}
                  fontWeight={700}
                  fontSize={20}
                  lineHeight={'22px'}
                  hasSymbol={false}
                  extend={css.itemPrice}
                />
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide className={`${css.side_btn_dell} ${css.slide_btn}`}>
        <button onClick={onClickRemoveProduct}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.4 2.63636C8.18783 2.63636 7.98434 2.72256 7.83431 2.876C7.68429 3.02944 7.6 3.23755 7.6 3.45455V4.27273H12.4V3.45455C12.4 3.23755 12.3157 3.02944 12.1657 2.876C12.0157 2.72256 11.8122 2.63636 11.6 2.63636H8.4ZM14 4.27273V3.45455C14 2.80356 13.7471 2.17924 13.2971 1.71892C12.847 1.2586 12.2365 1 11.6 1H8.4C7.76348 1 7.15303 1.2586 6.70294 1.71892C6.25286 2.17924 6 2.80356 6 3.45455V4.27273H2.8C2.35817 4.27273 2 4.63904 2 5.09091C2 5.54278 2.35817 5.90909 2.8 5.90909H3.6V16.5455C3.6 17.1964 3.85286 17.8208 4.30294 18.2811C4.75303 18.7414 5.36348 19 6 19H14C14.6365 19 15.247 18.7414 15.6971 18.2811C16.1471 17.8208 16.4 17.1964 16.4 16.5455V5.90909H17.2C17.6418 5.90909 18 5.54278 18 5.09091C18 4.63904 17.6418 4.27273 17.2 4.27273H14ZM5.2 5.90909V16.5455C5.2 16.7625 5.28429 16.9706 5.43431 17.124C5.58434 17.2774 5.78783 17.3636 6 17.3636H14C14.2122 17.3636 14.4157 17.2774 14.5657 17.124C14.7157 16.9706 14.8 16.7624 14.8 16.5455V5.90909H5.2ZM8.4 8.36364C8.84183 8.36364 9.2 8.72995 9.2 9.18182V14.0909C9.2 14.5428 8.84183 14.9091 8.4 14.9091C7.95817 14.9091 7.6 14.5428 7.6 14.0909V9.18182C7.6 8.72995 7.95817 8.36364 8.4 8.36364ZM10.8 9.18182C10.8 8.72995 11.1582 8.36364 11.6 8.36364C12.0418 8.36364 12.4 8.72995 12.4 9.18182V14.0909C12.4 14.5428 12.0418 14.9091 11.6 14.9091C11.1582 14.9091 10.8 14.5428 10.8 14.0909V9.18182Z"
              fill="white"
            />
          </svg>
        </button>
      </SwiperSlide>
    </Swiper>
  );
});
