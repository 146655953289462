import {NetworkStatus} from '../../utils/connect/connectConstant';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {RootState} from '../store';
import {directCheckout} from '../api/directCheckout';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { cartCheckout } from '../api/cart/cartCheckout';

interface ICheckoutForm {
  fullName: string;
  phone: string;
}

interface ICheckoutFormBase extends ICheckoutForm {
  fullName: string;
  phone: string;
  checkoutNotes: string;
}

type ICheckoutFormType = {
  value: ICheckoutForm;
  case: 'request';
} | {
  value: ICheckoutFormBase;
  case: 'base';
} | { case: undefined; value?: undefined };

interface ICheckoutState {
  networkStatus: NetworkStatus,
  checkoutErrorMessage?: string,
  formNetworkStatus: NetworkStatus,
  checkoutForm: ICheckoutFormType | undefined
}

const initialState: ICheckoutState = {
  networkStatus: NetworkStatus.None,
  formNetworkStatus: NetworkStatus.None,
  checkoutForm: undefined,
  checkoutErrorMessage: undefined,
};

export const checkoutFormGet = createAppAsyncThunk('checkoutFormGet', async (_, thunkAPI) => {
  return await thunkAPI.extra.portApi.checkoutFormGet({});
});


export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    resetNetworkStatus: (state, { payload }: PayloadAction<'networkStatus' | 'formNetworkStatus'>) => {
      state[payload] = NetworkStatus.None;
    }
  },
  extraReducers: builder => {
    builder.addCase(directCheckout.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(directCheckout.fulfilled, state => {
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(directCheckout.rejected, (state, action) => {
      state.networkStatus = NetworkStatus.Failed;
      state.checkoutErrorMessage = action.error.message;
    });
    builder.addCase(cartCheckout.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(cartCheckout.fulfilled, state => {
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(cartCheckout.rejected, (state, action) => {
      state.networkStatus = NetworkStatus.Failed;
      state.checkoutErrorMessage = action.error.message;
    });
    builder.addCase(checkoutFormGet.pending, state => {
      state.formNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(checkoutFormGet.fulfilled, (state, action) => {
      state.formNetworkStatus = NetworkStatus.Done;
      state.checkoutForm = action.payload.checkoutForm.checkoutForm
    });
    builder.addCase(checkoutFormGet.rejected, state => {
      state.formNetworkStatus = NetworkStatus.Failed;
    });
  },
});

export const { resetNetworkStatus } = checkoutSlice.actions;
export const checkoutState = (state: RootState) => state[checkoutSlice.name];
