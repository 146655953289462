import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from '../UI/Text/Text';
import { emptycartRules } from './emptycart.style';
import useCurrency from 'src/hooks/useCurrency';
import { Icon } from '../Icon/Icon';
import { telegramStarsString } from 'src/utils/constants';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';

export interface IStyles {
  marginBottom?: number;
}

const EmptyCart = function EmptyCart() {
  const { css } = useStyle(emptycartRules);
  const currency = useCurrency();
  const { t } = useTranslation()
  const { config } = useAppSelector(configState);
  return (
    <div className={css.wrp}>
      <Icon name={'empty-cart'} properties={{ className: css.img }} />
      <Text
        text={t('emptyCart.yourCartIsEmpty')}
        mod="text"
        fontSize={20}
        lineHeight="26px"
        fontWeight={800}
        extend={css.title}
        textTransform="uppercase"
      />
      {
        config.cartMinimalPrice > 0 && (
          <Text
            text={`${t('emptyCart.minimumOrderFrom', config.cartMinimalPrice)} ${currency !== telegramStarsString? currency: ''}`}
            mod="text"
            fontSize={14}
            lineHeight="18px"
            fontWeight={400}
            svg={currency === telegramStarsString? currency : undefined}
          />
        )
      }

    </div>
  );
};

export default memo(EmptyCart);
