import { StatusResponse } from '@teleport/schemas-protobuf';
import { BlockType, IMainStateData, IUIBlocks } from '../slices/mainSlice';

export const MainTranslator = {
  fromStatusResponse(response: StatusResponse): IMainStateData {
    const blocks: Record<string, IUIBlocks> = {};
    const order = [];

    response.mainPageBlocks.forEach(block => {
      switch (block.blockData.case) {
        case BlockType.TextBlock:
          blocks[block.blockData.value.uuid] = {
            uuid: block.blockData.value.uuid,
            title: block.blockData.value.title,
            text: block.blockData.value.text,
            accentColor: block.blockData.value.accentColor,
            button: block.blockData.value.button,
            type: BlockType.TextBlock
          };
          order.push(block.blockData.value.uuid);
          return;
        case BlockType.StoriesBlock:
          blocks['storiesBlock']  = {
            items: block.blockData.value.items,
            type: BlockType.StoriesBlock
          };
          order.push(BlockType.StoriesBlock);
          return;
        case BlockType.SliderBlock:
          blocks[block.blockData.value.uuid] = {
            uuid: block.blockData.value.uuid,
            items: block.blockData.value.items,
            type: BlockType.SliderBlock
          };
          order.push(block.blockData.value.uuid);
          return;
        case BlockType.SelectionBlock:
          blocks[block.blockData.value.uuid] = {
            uuid: block.blockData.value.uuid,
            title: block.blockData.value.title,
            visibility: block.blockData.value.visibility,
            enabled: block.blockData.value.enabled,
            type: BlockType.SelectionBlock
          };
          order.push(block.blockData.value.uuid);
          return;
        default:
          console.warn('unknown blockData case');
      }
    });

    return {
      order,
      blocks,
      hasMaintenance: response?.maintenance?.enabled,
      maintenance: {
        reasonText: response?.maintenance?.reasonText,
        supportLink: response?.maintenance?.supportLink,
      },
    };
  },
};
