import { Currency, DeliveryType, } from '@teleport/schemas-protobuf';
import { Language } from '../i18n/i18n.interface';

export enum Toggles {
  promoCodeEnabled = 'promoCodeEnabled',
  modifierFiltersEnabled = 'modifierFiltersEnabled',
  productEnabled2_2 = 'productEnabled2_2',
}

export interface IRemoteConfig {
  router?: {
    'port-api': string;
  };
  accentColor?: string;
  deliveryType?: DeliveryType;
  language?: Language;
  currency?: Currency;
  cartEnabled?: boolean;
  portBotUsername?: string;
  storeLogo?: string;
  storeName?: string;
  storeDescription?: string;
  codeName?: string;
  toggles?: Record<Toggles, boolean>;
  cartMinimalPrice?: number;
}

export interface IConfig {
  portApiUrl?: string;
  accentColor?: string;
  deliveryType?: DeliveryType;
  language?: Language;
  currency?: Currency;
  cartEnabled?: boolean;
  portBotUsername?: string;
  storeLogo?: string;
  storeName?: string;
  storeDescription?: string;
  codeName?: string;
  toggles?: Record<Toggles, boolean>;
  cartMinimalPrice?: number;
}
