import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';
import { padding } from '../../../utils/applyCSSProperty';

export interface ITextareaKeys {
  wrapper: {};
  textarea: {};
  message: {};
  top: {};
}

interface ITextareaProps {
  backgroundColor?: string;
  isInvaild: boolean;
  minHeight?: number;
}

export type ITextarea = IRuleFn<ITextareaKeys, ITextareaProps>;

const TextareaRuleFn: ITextarea = props => {
  const { colorWhite, colorRgbBlack, mainColor, colorRed } = props.theme;
  const { backgroundColor, isInvaild, minHeight = 120 } = props;
  const bgColor = backgroundColor ? backgroundColor : colorWhite;

  return {
    wrapper: {
      position: 'relative',
      ':before': {
        display: 'block',
        content: '""',
        position: 'absolute',
        pointerEvents: 'none',
        zIndex: 2,
        inset: -1,
        width: '100%',
        height: '100%',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: 12,
        borderColor: isInvaild ? colorRed : 'transparent',
      },
    },
    textarea: {
      minHeight,
      display: 'block',
      boxSizing: 'border-box',
      position: 'relative',
      resize: 'none',
      overflow: 'auto',
      width: '100%',
      backgroundColor: bgColor,
      borderRadius: 12,
      ...padding(20, 16),
      outline: 'none',
      ':-webkit-autofill': {
        WebkitBoxShadow: `inset 0 0 0 1000px ${bgColor}`,
      },
      border: 'none',
      // txt
      fontSize: 16,
      lineHeight: '20px',
      fontFamily: 'Onest, sans-serif',
      fontWeight: 400,
    },
    message: {
      marginTop: 4,
      color: isInvaild ? colorRed : `rgba(${colorRgbBlack}, .4)`,
      fontWeight: '500',
      lineHeight: '14px',
      fontSize: 12,

      '& a': {
        fontWeight: 600,
        color: mainColor,
      },
    },
    top: {
      marginBottom: 8,
      display: 'flex',
      justifyContent: 'space-between',
    },
  };
};

export const TextareaRules = createCachedStyle(TextareaRuleFn);
