import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getProductsBySearch } from '../api/getProductsBySearch';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { ProductsListTranslator } from '../translators/productsListTranslator';
import { RootState } from '../store';
import { IClientCatalogProductShort } from './productsListSlice';

interface SearchState {
  value: string;
  products: IClientCatalogProductShort[];
  networkStatus: NetworkStatus;
  isOpen: boolean;
}

const initialState: SearchState = {
  value: '',
  products: [],
  networkStatus: NetworkStatus.None,
  isOpen: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    resetSearch: state => {
      state.value = '';
      state.products = [];
      state.networkStatus = NetworkStatus.None;
    },
    setValue: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    }
  },
  extraReducers: builder => {
    builder.addCase(getProductsBySearch.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getProductsBySearch.fulfilled, (state, action) => {
      state.products = ProductsListTranslator.fromProductsResponse(action.payload);
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getProductsBySearch.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const { setValue, setOpen, resetSearch } = searchSlice.actions;
export const searchState = (state: RootState) => state.search;
export const getSearchValue = (state: RootState) => state.search.value;
