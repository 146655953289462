import { Sorting } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../hooks/redux';
import { IFilter } from '../slices/filterSlice';

export interface IGetProductsListParams {
  filters: IFilter[];
  sorting: Sorting;
}

export const getProductsList = createAppAsyncThunk(
  'productList',
  async (params: IGetProductsListParams, thunkAPI) => {
    return await thunkAPI.extra.portApi.clientCatalogProductList(params);
  },
);
