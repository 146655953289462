import { createAppAsyncThunk } from '../../../hooks/redux';
import {ICartProductWidthId} from '../../slices/cartSlice';
import {CartTranslator} from '../../translators/cartTranslator';

export const removeProductFromCart = createAppAsyncThunk('cart/removeProductFromCart', async (args: {products: ICartProductWidthId[], deletedUuid: string, id: string}, thunkAPI) => {
  const { products, id } = args;
  const translatedProducts = products.filter(el => el.id !== id).map(product => CartTranslator.toCartProduct(product));
  const result = await thunkAPI.extra.portApi.cartUpsert({ products: translatedProducts });
  return {
    total: CartTranslator.toCartPrice(result.totalV2),
    ...args,
  }
});
