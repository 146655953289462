import { FC, memo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { Price } from 'src/components/Price/Price';
import { productMainInfoRules } from './productMainInfo.style';
import {Link} from 'react-router-dom';
import { IClientCatalogProductFull } from 'src/redux/slices/productDetailSlice';
interface IProps {
  productData: IClientCatalogProductFull;
}

export const ProductMainInfo: FC<IProps> = memo(function ProductMainInfo(props) {
  const { css } = useStyle(productMainInfoRules);
  const { productData } = props;
  const { title, price, categories } = productData;

  const rootCategory = categories.find(el => el.type.case === 'rootCategory');

  return (
    <div>
      <div className={css.header}>
        <Text text={title} mod="title" />
        {price.discountEnabled ? (
          <div className={css.priceWrapper}>
            <Price amount={price.amount} hasSymbol={false} fontWeight={800} fontSize={20} lineHeight={'22px'} />
            <Price amount={price.oldAmount} hasSymbol={false} fontWeight={700} fontSize={16} lineHeight={'18px'} oldPrice />
          </div>
        ) : (
          <Price amount={price.amount} hasSymbol={false} fontWeight={800} fontSize={20} lineHeight={'22px'} />
        )}
      </div>
      {rootCategory && (
        <Link className={css.category} to={`/category/${rootCategory.type.value.uuid}`}>{rootCategory.type.value.title}</Link>
      )}
    </div>
  );
});
