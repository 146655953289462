import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { categoryCardRules } from './СategoryCard.style';
import Text from 'src/components/UI/Text/Text';
import { ImageComponent } from 'src/components/ImageComponent/ImageComponent';
import { v4 } from 'uuid';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { Icon } from '../Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { ICategoryWithProducts } from '../../redux/slices/categoriesSlice';

interface IProps {
  categoryData: ICategoryWithProducts;
}

export const CategoryCard: FC<IProps> = memo(function CategoryCard(props) {
  const { categoryData } = props;
  const category = categoryData.category
  const products= categoryData.products

  const navigate = useNavigate();
  
  const { tPlural } = useTranslation();
  const { css } = useStyle(categoryCardRules);
  const productsGrid = products.slice(0, 4);

  if (productsGrid.length < 4) {
    const missingElements = 4 - productsGrid.length;
    for (let i = 0; i < missingElements; i++) {
      productsGrid.push(null);
    }
  }

  const onCardClickFunction = () => {
    navigate(`/category/${category.uuid}`)
  }

  return (
    <div className={css.card} onClick={onCardClickFunction} >
      <div className={css.productsGrid}>
        {productsGrid.map(product => {
          if (!product) {
            return <div className={css.emptyBlock} key={v4()}></div>;
          }
          if (product.previewImageUrls.length) {
            return (
              <div key={product.uuid} className={css.productImg}>
                <ImageComponent
                  alt="product-img"
                  src={product.previewImageUrls[0]}
                />
              </div>
            );
          }
          return (
            <div key={product.uuid} className={css.productImg}>
              <Icon name="no-image" properties={{ className: css.fallbackSvg }} />
            </div>
          );
        })}
      </div>
      <div>
        <Text mod="title" fontSize={14} lineHeight={'16px'} text={category.title} />
        <Text
          mod="text"
          text={tPlural('catalog.countProducts', products.length)}
          fontSize={12}
          lineHeight={'16px'}
          fontWeight={500}
        />
      </div>
    </div>
  );
});
