import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { NetworkStatus } from '../../utils/connect/connectConstant';
import { RootState } from '../store';
import { getCatalogMetadataWithProducts } from '../api/getCatalogMetadataWithProducts';

export interface ICategory {
  type: {
    value: IRootCategory;
    case: 'rootCategory';
  } | {
    value: ISubCategory;
    case: 'subCategory';
  }
  | { case: undefined; value?: undefined }
}

export interface IRootCategory {
  uuid: string;
  title: string;
  subCategoryEnabled: boolean;
}

export interface ISubCategory {
  categoryUuid: string;
  uuid: string;
  title: string;
}

interface ISubCategoryAndProductsCount {
  subCategory: ISubCategory;
  productsCount: number;
}

export interface ICategoryCustom {
  subCategories: ISubCategoryAndProductsCount[];
  category: IRootCategory;
  productsCount: number;
}

export interface ICategoryWithProducts {
  subCategories: ISubCategoryAndProductsCount[];
  category: IRootCategory;
  productsCount: number;
  products: ICategoryProduct[]
}

interface ICategoryProduct {
  uuid: string;
  previewImageUrls: string[];
}

interface ICategoriesState {
  selectedCategory: IRootCategory | null;
  selectedSubCategory: ISubCategory | null;
  categories: ICategoryWithProducts[];
  networkStatus: NetworkStatus;
}

const initialState: ICategoriesState = {
  selectedCategory: null,
  selectedSubCategory: null,
  categories: [],
  networkStatus: NetworkStatus.None,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategory: (state, action: PayloadAction<IRootCategory>) => {
      state.selectedCategory = action.payload;
    },
    setSubCategory: (state, action: PayloadAction<ISubCategory>) => {
      state.selectedSubCategory = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getCatalogMetadataWithProducts.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getCatalogMetadataWithProducts.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getCatalogMetadataWithProducts.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const { setCategory, setSubCategory } = categoriesSlice.actions;

// selectors
export const selectorCategory = (state: RootState) => state.categories.categories;
export const selectorSelectedCategory = (state: RootState) => state.categories.selectedCategory;
const selectorSelectedSubCategory = (state: RootState) => state.categories.selectedSubCategory;

export const categoriesState = (state: RootState) => state[categoriesSlice.name];
export const selectedCategoryData = createSelector(
  [selectorSelectedCategory, selectorSelectedSubCategory],
  (selectedCategory, selectedSubCategory) => {
    return { selectedCategory, selectedSubCategory };
  },
);
