import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import { createAppAsyncThunk } from 'src/hooks/redux';
import { ModifiersTranslator } from '../translators/modifiersTranslator';

interface ModifiersState {
  networkStatus: NetworkStatus
  modifiers: IModifier[];
}

export interface IModifierVariant extends IClientModifier {
  modifierUuid?: string
}

export enum IModifierType {
  Unspecified = 0,
  Toggle = 1,
  Chips = 2,
  List = 3,
}

export interface IModifier  {
  uuid: string,
  uiType: IModifierType,
  title: string,
  descriptionLink: string,
  affectsPrice: boolean,
  variants: IModifierVariant[],
}

export interface IClientModifier{
  modifierVariantUuid: string;
  modifierVariantTitle: string;
}

export interface IProductModifierImpact {
  modifierVariantUuid: string;
  priceImpact: number;
}

export interface IClientModifier {
  modifierVariantUuid: string;
  modifierVariantTitle: string;
}

const initialState: ModifiersState = {
  modifiers: [],
  networkStatus: NetworkStatus.None
};

export const getAllModifiers = createAppAsyncThunk<IModifier[]>('modifiers/getAllModifiers', 
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portApi.modifierList({})
    return ModifiersTranslator.formModifierForResponse(result)
  }
)

export const modifiersSlice = createSlice({
  name: 'modifiers',
  initialState,
  reducers: {
    resetModifiersNetworkStatus: state => {
      state.networkStatus = NetworkStatus.None
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllModifiers.pending, state => {
      state.networkStatus = NetworkStatus.Loading
    });
    builder.addCase(getAllModifiers.fulfilled, (state, action) => {
      state.networkStatus = NetworkStatus.Done
      state.modifiers = action.payload
    });
    builder.addCase(getAllModifiers.rejected, state => {
      state.networkStatus = NetworkStatus.Failed
    })
  },
});

export const { resetModifiersNetworkStatus } = modifiersSlice.actions;
export const modifiersState = (state: RootState) => state[modifiersSlice.name];
