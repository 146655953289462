import { createAppAsyncThunk } from '../../../hooks/redux';
import {CartTranslator} from '../../translators/cartTranslator';
import {ICartProductWidthId} from '../../slices/cartSlice';

export const changeQuantityProduct = createAppAsyncThunk('cart/changeQuantityProduct', async (args: {
  action: 'increment' | 'decrement',
  id: string,
  productUuid: string,
  products: ICartProductWidthId[],
}, thunkAPI) => {

  const translatedProducts = args.action === 'increment'
    ? CartTranslator.toIncrementArray(args.products, args.id)
    : CartTranslator.toDecrementArray(args.products, args.id)
  const result = await thunkAPI.extra.portApi.cartUpsert({products: translatedProducts})

  return {
    total: CartTranslator.toCartPrice(result.totalV2),
    ...args,
  };
});
