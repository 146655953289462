import { Category, Filter } from '@teleport/schemas-protobuf';
import { IRootCategory, ISubCategory } from '../slices/categoriesSlice';
import { IFilter } from '../slices/filterSlice';

export interface IFilterComplex {
  query?: string;
  category?: IRootCategory;
  subCategory?: ISubCategory;
  selectionUuid?: string;
}

export const FilterTranslator = {
  toCategoryFilter(category: IRootCategory): IFilter {
    const categoryInstance = new Category({
      type: {
        case: 'rootCategory',
        value: { title: category.title, uuid: category.uuid },
      },
    });
    return {
      filterDefaultState: { case: 'categoryFilter', value: { category: categoryInstance } },
      isSystem: false,
      tag: '',
      title: ''
    }
  },

  toSubCategoryFilter(subCategory: ISubCategory): IFilter {
    const subCategoryInstance = new Category({
      type: {
        case: 'subCategory',
        value: { title: subCategory.title, uuid: subCategory.uuid },
      },
    });

    return {
      filterDefaultState: { case: 'categoryFilter', value: { category: subCategoryInstance } },
      isSystem: false,
      tag: '',
      title: ''
    }
  },

  toSearchFilter(query: string): IFilter {
    return {
      filterDefaultState: { case: 'searchFilter', value: { query } },
      isSystem: false,
      tag: '',
      title: ''
    }
  },

  toSelectionFilter(selectionUuid: string): IFilter {
    return {
      filterDefaultState: { value: { selectionUuid }, case: 'selectionFilter' },
      isSystem: false,
      tag: '',
      title: ''
    }
  },

  toFiltersComplex(params: IFilterComplex) {
    const {
      query,
      category: selectedCategory,
      subCategory: selectedSubCategory,
      selectionUuid,
    } = params;
    const filters: Filter[] = [];

    if (selectedCategory) {
      const category = new Category({
        type: {
          case: 'rootCategory',
          value: { title: selectedCategory.title, uuid: selectedCategory.uuid },
        },
      });
      filters.push(new Filter({
        filterDefaultState: { case: 'categoryFilter', value: { category } },
      }));
    }

    if (selectedSubCategory) {
      const subCategory = new Category({
        type: {
          case: 'subCategory',
          value: { title: selectedSubCategory.title, uuid: selectedSubCategory.uuid },
        },
      });
      filters.push(new Filter({
        filterDefaultState: { case: 'categoryFilter', value: { category: subCategory } },
      }));
    }

    if(query) {
      const searchFilter = new Filter({
        filterDefaultState: { case: 'searchFilter', value: { query } },
      });
      filters.push(searchFilter);
    }

    if (selectionUuid) {
      const selectionFilter = new Filter({
        filterDefaultState: { value: { selectionUuid }, case: 'selectionFilter' },
      });
      filters.push(selectionFilter);
    }

    return filters;
  },

  toIFilter(filter: Filter): IFilter {
    const resultFilter = {
      tag: filter.tag,
      title: filter.title,
      isSystem: filter.isSystem,
      filterDefaultState: { case: undefined, value: undefined },
    }
    switch (filter.filterDefaultState.case) {
      case 'boolFilter':
        resultFilter.filterDefaultState = {
          value: { checked: filter.filterDefaultState.value.checked },
          case: 'boolFilter',
        }
        return resultFilter;
      case 'multiselectFilter':
        resultFilter.filterDefaultState = {
          value: {availableOptions: filter.filterDefaultState.value.availableOptions},
          case: 'multiselectFilter',
        }
        return resultFilter;
      case 'rangeFilter':
        resultFilter.filterDefaultState = {
          value: filter.filterDefaultState.value,
          case: 'rangeFilter',
        }
        return resultFilter;
      case 'searchFilter':
        resultFilter.filterDefaultState = {
          value: filter.filterDefaultState.value,
          case: 'searchFilter',
        }
        return resultFilter;
      case 'categoryFilter':
        resultFilter.filterDefaultState = {
          value: filter.filterDefaultState.value,
          case: 'categoryFilter',
        }
        return resultFilter;
      case 'selectionFilter':
        resultFilter.filterDefaultState = {
          value: filter.filterDefaultState.value,
          case: 'selectionFilter',
        }
        return resultFilter;
      default:
        return undefined;
    }
  },
};
