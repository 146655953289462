import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import { ProductSlider } from 'src/blocks/Product/components/ProductSlider/ProductSlider';
import { ProductMainInfo } from 'src/blocks/Product/components/ProductMainInfo/ProductMainInfo';
import Wrapper from 'src/components/Wrapper/Wrapper';
import { useStyle } from 'src/utils/theme/useStyle';
import Container from 'src/components/Container/Container';
import { ProductDescription } from 'src/blocks/Product/components/ProductDescription/ProductDescription';
import { useTheme } from 'src/utils/theme/useTheme';
import { productPageRules } from './productPage.style';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  clearChosenModifiersAndProduct,
  DeliveryActionType,
  getProductDetail,
  productState,
  resetNetworkStatus,
  setErrorModifiersUuids,
  setNeedRedirectToProductPage
} from '../../redux/slices/productDetailSlice';
import useLoader from 'src/hooks/useLoader';
import { NetworkStatus } from 'src/utils/connect/connectConstant';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate, useParams } from 'react-router-dom';
import { cartState, resetCartNetworkStatus } from '../../redux/slices/cartSlice';
import Selection from 'src/components/Selection/Selection';
import { selectionsProductPage } from '../../redux/slices/selectionsSlice';
import { configState } from 'src/redux/slices/configSlice';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import Header from '../../components/Header/Header';
import { productToCardAppend } from '../../redux/api/cart/productToCardAppend';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { ProductModificators } from 'src/blocks/Product/components/ProductModificators/ProductModificators';
import { getAllModifiers, IModifierType, modifiersState, resetModifiersNetworkStatus } from 'src/redux/slices/modifiersSlice';
import ProductSliderSkeleton from '../../blocks/Product/components/ProductSlider/ProductSliderSkeleton';
import ProductMainInfoSkeleton from '../../blocks/Product/components/ProductMainInfo/ProductMainInfoSkeleton';
import {
  ProductDescriptionSkeleton
} from '../../blocks/Product/components/ProductDescription/ProductDescriptionSkeleton';
import { SkeletonText } from '../../components/UI/SkeletonText/SkeletonText';
import { SkeletonImage } from '../../components/UI/SkeletonImage/SkeletonImage';
import { modalErrorState, openErrorModal } from '../../redux/slices/modalErrorSlice';

export const ProductPage: FC = memo(function ProductPage() {
  const { css } = useStyle(productPageRules);
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const { product, networkStatus, chosenModifiers } = useAppSelector(productState);
  const { modalErrorIsOpen } = useAppSelector(modalErrorState);
  const { networkStatus: modifiersNetworkStatus, modifiers } = useAppSelector(modifiersState);
  const productPageSelections = useAppSelector(selectionsProductPage);
  const { networkStatusCardAppend, errorCartAppend } = useAppSelector(cartState);
  const { config } = useAppSelector(configState);
  const { webApp } = useTelegram();
  const { t } = useTranslation()
  const [productIsInCartState, setProductIsInCartState] = useState(false)

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useEffect(() => {
    if (networkStatusCardAppend === NetworkStatus.Done) {
      setProductIsInCartState(true)
    }
    return () => {
      dispatch(resetCartNetworkStatus('networkStatusCardAppend'))
    }
  }, [networkStatusCardAppend, dispatch])

  function openProductLink() {
    if (product.deliveryAction.type === DeliveryActionType.Link) {
      webApp.openLink(product.deliveryAction.value);
    }
  }

  // Заказать
  function toOrder() {
    if(checkTheRequiredModifiers()) {
      dispatch(setNeedRedirectToProductPage(false))
      navigate(`/make-single-order/${productId}`);
    }
  }

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate(-1);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    dispatch(getProductDetail(productId));
    dispatch(getAllModifiers())
  }, [dispatch, productId]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Failed) {
      dispatch(openErrorModal({errorTitle: t('productPage.failedToGetProductInformation')}))
    }
    if (modifiersNetworkStatus === NetworkStatus.Failed ) {
      dispatch(openErrorModal({errorTitle: t('productPage.failedToGetModifiers')}))
    }
    if (networkStatusCardAppend === NetworkStatus.Failed) {
      if (errorCartAppend.includes('CART_ERROR_OUTDATED')) {
        dispatch(getProductDetail(productId));
      } else {
        dispatch(openErrorModal({errorTitle: t('productPage.failedToAddTheProductToTheCart')}));
      }
    }
  }, [t, dispatch, errorCartAppend, productId, networkStatus, modifiersNetworkStatus, networkStatusCardAppend])

  useEffect(() => {
    dispatch(resetNetworkStatus())
    dispatch(resetModifiersNetworkStatus())
  }, [dispatch, modalErrorIsOpen]);

  const addProductToCartFn = () => {
    if (checkTheRequiredModifiers()) {
      dispatch(productToCardAppend({productUuid: productId, chosenModifiers: chosenModifiers.map(el => {return {modifierVariantUuid: el.modifierVariantUuid, modifierVariantTitle: el.modifierVariantTitle}})}))
    }
  };

  const checkTheRequiredModifiers = () => {
    const uuidsNonSelectedModifiers = modifiers
      .filter(modifier =>
        // Если модификатор типа чипсы или список
        (modifier.uiType === IModifierType.Chips || modifier.uiType === IModifierType.List) &&
        // Если хотя бы 1 вариант модификатора активен
        modifier.variants.some(variant =>  product.modifiers.map(activeVariant => activeVariant.modifierVariantUuid).includes(variant.modifierVariantUuid)) &&
        // Если ни один вариант модификатора не находится в выбранных вариантах
        !modifier.variants.some(variant =>  chosenModifiers.map(activeVariant => activeVariant.modifierVariantUuid).includes(variant.modifierVariantUuid))
      )
      .map(modifier => modifier.uuid)

    if (uuidsNonSelectedModifiers.length) {
      dispatch(setErrorModifiersUuids(uuidsNonSelectedModifiers))
      return false
    }

    return true
  }

  useEffect(() => {
    dispatch(clearChosenModifiersAndProduct())
  }, [dispatch, productId])


  const content = useMemo(() => {
    if (!product || networkStatus === NetworkStatus.Loading) {
      return (
        <div className={css.productInner}>
          <ProductSliderSkeleton />
          <Wrapper extend={css.wrapperInfo} propsStyles={{paddingBottom: 0}}>
            <Container>
              <ProductMainInfoSkeleton />
              <div className={css.skeletonProductModifier}>
                <div className={css.skeletonProductModifierTop}>
                  <SkeletonText width={'40%'} height={18} />
                  <SkeletonText width={'70px'} height={18} />
                </div>
                <div className={css.skeletonProductModifierContent}>
                  <SkeletonImage width={40} height={34} borderRadius={'8px'} />
                  <SkeletonImage width={34} height={34} borderRadius={'8px'} />
                  <SkeletonImage width={34} height={34} borderRadius={'8px'} />
                  <SkeletonImage width={34} height={34} borderRadius={'8px'} />
                </div>
              </div>
              <ProductDescriptionSkeleton/>
            </Container>
          </Wrapper>
        </div>
      )
    } else {
      return (
        <div className={css.productInner}>
          <ProductSlider productData={product}/>
          <Wrapper extend={css.wrapperInfo} propsStyles={{paddingBottom: 0}}>
            <Container>
              <ProductMainInfo productData={product}/>
              {product.modifiers &&
                <ProductModificators modifiers={modifiers} variants={product.modifiers}/>}
              {product.description && <ProductDescription text={product.description}/>}
            </Container>
            {productPageSelections.length > 0 && (
              <Wrapper
                propsStyles={{
                  // TODO fixme
                  // borderRadius: '16px 16px 0px 0px',
                  backgroundColor: theme.background,
                  paddingBottom: '20px',
                }}
                extend={css.wrapperCollections}
              >
                {productPageSelections.map(selection => (
                  <Selection selectionData={selection} key={selection.title} location="productPage"/>
                ))}
              </Wrapper>
            )}
          </Wrapper>
        </div>
      )
    }
  }, [
    css,
    networkStatus,
    product,
    productPageSelections,
    theme,
    modifiers,
  ])

  return (
    <Layout
      header={<Header/>}
      footer={
        <Footer>
          {config.cartEnabled ? (
            <Button
              text={productIsInCartState ? t('productPage.addMore') : t('productPage.addToCart')}
              onClick={addProductToCartFn}
              propsStyles={{width: '100%', height: 62}}
              disabled={networkStatusCardAppend === NetworkStatus.Loading || networkStatus === NetworkStatus.Loading}
            />
          ) : config.deliveryType === DeliveryType.REQUEST ? (
            <Button text={t('productPage.order')} propsStyles={{ width: '100%', height: 62 }} onClick={toOrder} disabled={networkStatus === NetworkStatus.Loading} />
          ) : config.deliveryType === DeliveryType.SHOWCASE ? (
            // Добавить prop revertIcon
            <Button
              text={t('productPage.proceedToPurchase')}
              propsStyles={{width: '100%', height: 62}}
              icon="arrow-top-right"
              iconAtTheEnd={true}
              disabled={networkStatus === NetworkStatus.Loading}
              onClick={openProductLink}
            />
          ) : null}
        </Footer>
      }
    >
      {content}
    </Layout>
  );
});
