import {ICartPrice, ICartProductWidthId} from 'src/redux/slices/cartSlice';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { CartTranslator } from '../../translators/cartTranslator';
import {PromoCodeTranslator} from '../../translators/promocodeTranslator';
import {IPromocode} from '../../../types/promocode';

export const cartGet = createAppAsyncThunk('cart/cartGet', async (_, thunkAPI): Promise<{products: ICartProductWidthId[], total: ICartPrice, promocode: IPromocode}> => {
  const cartGetResponse = await thunkAPI.extra.portApi.cartGet({});
  const cartProducts = cartGetResponse.products;
  const products = cartProducts.map(product => {
    const cartProduct: ICartProductWidthId = {
      id:  `${product.productUuid}-${product.chosenModifiers.map(modifier => modifier.modifierVariantUuid.slice(0, 4)).join('-')}`,
      productUuid: product.productUuid,
      quantity: product.quantity,
      chosenModifiers: product.chosenModifiers,
      productTitle: product.productTitle,
      price: CartTranslator.toPrice(product.price),
      productImageUrl: product.productImageUrl,
      productIsFavorite: product.productIsFavorite,
    }
    return cartProduct
  });
  return {
    products: products,
    total: CartTranslator.toCartPrice(cartGetResponse.totalV2),
    promocode: PromoCodeTranslator.toPromoCode(cartGetResponse.promocode)
  };
});

